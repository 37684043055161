<template>
  <div>
    <div class="user">
      <div class="left">
        <h3>会员中心</h3>
        <div class="name"><img src="/images/jijin_icn.png" />基金</div>
        <div
          class="name1"
          @click="tomenu(0, 0)"
          style="padding-bottom: 25px"
          v-if="$width >= 767"
        >
          已认购的基金
        </div>
        <div class="name1" @click="tomenu(0, 0)" v-if="$width < 767">
          已认购的基金
        </div>
        <div class="name"><img src="/images/geren_icn.png" />个人中心</div>
        <div class="name1" @click="tomenu(1)">个人信息</div>
        <div
          class="name1"
          v-if="$width > 767"
          style="padding-bottom: 25px"
          @click="tomenu(2)"
        >
          能力测评
        </div>
        <div class="name1" v-if="$width < 767" @click="tomenu(2)">能力测评</div>

        <div class="name"><img src="/images/anquan_icn.png" />安全中心</div>
        <div class="name1" @click="menu = 3">密码修改</div>
      </div>

      <div class="right" v-if="menu == 0">
        <div class="huiyuan_box_r_home_name">天润资本</div>
        <div class="huiyuan_box_r_home_ziliao" v-if="$width >= 767">
          <img src="/images/shouji_icn.png" />
          <div style="padding: 0 50px 0 10px">
            已绑定手机：{{ user.phonenumber }}
          </div>
          <img src="/images/youxiang_icn.png" />
          <div style="padding: 0 50px 0 10px">已绑定邮箱：{{ user.email }}</div>
          <div style="color: #8e8e8e; cursor: pointer" @click="menu = 1">
            修改
          </div>
        </div>
        <div
          class="huiyuan_box_r_home_ziliao"
          v-if="$width < 767"
          style="border: none"
        >
          <img src="/images/shouji_icn.png" />
          <div style="padding: 0 50px 0 10px">
            已绑定手机：{{ user.phonenumber }}
          </div>
        </div>
        <div class="huiyuan_box_r_home_ziliao" v-if="$width < 767">
          <img src="/images/youxiang_icn.png" />
          <div style="padding: 0 50px 0 10px">已绑定邮箱：{{ user.email }}</div>
          <div style="color: #8e8e8e; cursor: pointer" @click="menu = 1">
            修改
          </div>
        </div>

        <div class="nav">
          <span :class="itsActive == 0 ? 'active' : ''" @click="itsActive = 0">
            已认购的基金
          </span>
          <!-- <span :class="itsActive == 1 ? 'active' : ''" @click="itsActive = 1">
            募集中的基金
          </span> -->
        </div>

        <el-row class="th">
          <el-col :span="16">基金名称</el-col>
          <el-col :span="8">基金详情</el-col>
        </el-row>

        <div v-if="gmlist.length > 0">
          <el-row
            class="tr"
            :class="i % 2 == 1 ? 'tr2' : ''"
            v-for="(gm, idx) of gmlist"
            :key="'gm' + idx"
          >
            <el-col :span="16">{{ gm.pName }}</el-col>
            <el-col :span="8">
              <a class="btn" @click="toDetail(gm)">点击查看</a>
            </el-col>
          </el-row>
        </div>

        <div class="nocontent" v-if="gmlist.length == 0">暂无内容</div>
        <!-- <div style="text-align: center; padding: 30px">
          <el-pagination background layout="prev, pager, next" :total="1000">
          </el-pagination>
        </div> -->
      </div>

      <div class="right" v-if="menu == 1">
        <h3 v-if="$width >= 767">个人信息</h3>
        <h3 v-if="$width < 767" style="padding: 15px 0; margin-bottom: 10px">
          个人信息
        </h3>
        <el-row class="formrow">
          <el-col
            :span="$width >= 767 ? 4 : 6"
            :offset="$width >= 767 ? 2 : 1"
            class="formname"
            >用户名</el-col
          >
          <el-col :span="14">
            <el-input
              v-model="user.nickName"
              placeholder="请输入内容"
            ></el-input>
          </el-col>
        </el-row>
        <el-row class="formrow">
          <el-col
            :span="$width >= 767 ? 4 : 6"
            :offset="$width >= 767 ? 2 : 1"
            class="formname"
            >姓名</el-col
          >
          <el-col :span="14">
            <el-input
              v-model="user.userName"
              placeholder="请输入内容"
            ></el-input>
          </el-col>
        </el-row>
        <el-row class="formrow">
          <el-col
            :span="$width >= 767 ? 4 : 6"
            :offset="$width >= 767 ? 2 : 1"
            class="formname"
            >身份证</el-col
          >
          <el-col :span="14">
            <el-input v-model="user.idCard" placeholder="请输入内容"></el-input>
          </el-col>
        </el-row>
        <el-row class="formrow">
          <el-col
            :span="$width >= 767 ? 4 : 6"
            :offset="$width >= 767 ? 2 : 1"
            class="formname"
            >手机号</el-col
          >
          <el-col :span="14">
            <el-input
              v-model="user.phonenumber"
              placeholder="请输入内容"
            ></el-input>
          </el-col>
        </el-row>
        <el-row class="formrow">
          <el-col
            :span="$width >= 767 ? 4 : 6"
            :offset="$width >= 767 ? 2 : 1"
            class="formname"
            >邮箱</el-col
          >
          <el-col :span="14">
            <el-input v-model="user.email" placeholder="请输入内容"></el-input>
          </el-col>
        </el-row>
        <el-row class="formrow">
          <el-col
            :span="$width >= 767 ? 4 : 6"
            :offset="$width >= 767 ? 2 : 1"
            class="formname"
            >性别</el-col
          >
          <!--   <el-col :span="14">
          <el-input v-model="user.sex" placeholder="请输入内容"></el-input>
          </el-col> -->
          <el-col :span="14">
            <el-select
              v-model="user.sex"
              placeholder="请选择"
              class="inner"
              style="width: 100%"
            >
              <el-option
                v-for="sex in [
                  { label: '男', value: '1' },
                  { label: '女', value: '0' },
                ]"
                :key="sex.value"
                :label="sex.label"
                :value="sex.value"
              >
                <span style="float: left">{{ sex.label }}</span>
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row class="formrow">
          <el-col
            :span="$width >= 767 ? 4 : 6"
            :offset="$width >= 767 ? 2 : 1"
            class="formname"
            >地址</el-col
          >
          <el-col :span="14">
            <el-input
              v-model="user.address"
              placeholder="请输入内容"
            ></el-input>
          </el-col>
        </el-row>
        <el-row class="formrow">
          <el-col
            :span="14"
            :offset="5"
            class="btn"
            @click.native="updateUserinfo"
          >
            确定修改
          </el-col>
        </el-row>
      </div>

      <div class="right" v-if="menu == 2">
        <div class="huiyuan_box_r_home_name">天润资本</div>
        <div class="huiyuan_box_r_home_ziliao" v-if="$width >= 767">
          <img src="/images/shouji_icn.png" />
          <div style="padding: 0 50px 0 10px">
            已绑定手机：{{ user.phonenumber }}
          </div>
          <img src="/images/youxiang_icn.png" />
          <div style="padding: 0 50px 0 10px">已绑定邮箱：{{ user.email }}</div>
          <div style="color: #8e8e8e; cursor: pointer" @click="menu = 1">
            修改
          </div>
        </div>
        <div
          class="huiyuan_box_r_home_ziliao"
          v-if="$width < 767"
          style="border: none"
        >
          <img src="/images/shouji_icn.png" />
          <div style="padding: 0 50px 0 10px">
            已绑定手机：{{ user.phonenumber }}
          </div>
        </div>
        <div class="huiyuan_box_r_home_ziliao" v-if="$width < 767">
          <img src="/images/youxiang_icn.png" />
          <div style="padding: 0 50px 0 10px">已绑定邮箱：{{ user.email }}</div>
          <div style="color: #8e8e8e; cursor: pointer" @click="menu = 1">
            修改
          </div>
        </div>

        <div class="pinggu">
          <div style="height: 65px; line-height: 65px">您的评估结果 ></div>
          <div style="font-size: 16px; font-weight: bold; text-align: center">
            投资者风险评估结果确认书
          </div>
          <div class="line" style="padding: 10px 0">
            投资者
            <span class="underline"> 于娜: </span>
          </div>
          <div class="line">
            <span style="margin-right: 25px"></span
            >《私募基金投资者风险调查问卷（个人版）》的总分为100分，根据您所选择的问题答案，您对投资风险的整体承受程度及您的风险偏好总得分为：【
            <span class="underline">76</span>】分。
            根据《投资者风险承受能力评估评分表（个人版）》的评价，您的风险承受能力为：【
            <span class="underline">积极型</span> 】，适合您的基金产品评级为【
            <span class="underline">低/中/高风险产品</span> 】。
          </div>
          <div class="line" style="padding: 30px 0 10px">投资者声明：</div>
          <div class="line">
            1、本人已如实填写《私募基金投资者风险调查问卷（个人版）》，并了解了自己的风险承受类型和适合购买的产品类型，且是为自己购买私募基金产品。
          </div>
          <div class="line">
            2、本人符合如下【<span class="underline">A</span
            >】的合格投资者财务条件：
          </div>
          <div class="line">
            A、符合金融资产不低于300万元（金融资产包括银行存款、股票、债券、基金份额、资产管理计划、银行理财产品、信托计划、保险产品、期货权益等）；
          </div>
          <div class="line">B、符合最近三年个人年均收入不低于50万元。</div>
          <div class="line" style="padding: 30px 0 10px">
            附：投资者风险承受能力评估评分表（个人版）
          </div>
          <div class="line">1、分值设置</div>
          <table border="1" cellspacing="0" cellpadding="0" width="100%">
            <tbody>
              <tr bgcolor="#E4E4E4">
                <td width="43"><p align="center">序号</p></td>
                <td width="38">
                  <p align="center">1</p>
                </td>
                <td width="44"><p align="center">2</p></td>
                <td width="44"><p align="center">3</p></td>
                <td width="45"><p align="center">4</p></td>
                <td width="36"><p align="center">5</p></td>
                <td width="43"><p align="center">6</p></td>
                <td width="38"><p align="center">7</p></td>
                <td width="42"><p align="center">8</p></td>
                <td width="36"><p align="center">9</p></td>
                <td width="45"><p align="center">10</p></td>
                <td width="42"><p align="center">11</p></td>
                <td width="36"><p align="center">12</p></td>
                <td width="31"><p align="center">13</p></td>
                <td width="31"><p align="center">14</p></td>
              </tr>
              <tr>
                <td width="43"><p align="center">A</p></td>
                <td width="38"><p align="center">1</p></td>
                <td width="44"><p align="center">1</p></td>
                <td width="44"><p align="center">1</p></td>
                <td width="45"><p align="center">1</p></td>
                <td width="36"><p align="center">1</p></td>
                <td width="43"><p align="center">1</p></td>
                <td width="38"><p align="center">1</p></td>
                <td width="42"><p align="center">1</p></td>
                <td width="36"><p align="center">1</p></td>
                <td width="45"><p align="center">1</p></td>
                <td width="42"><p align="center">1</p></td>
                <td width="36"><p align="center">1</p></td>
                <td width="31"><p align="center">1</p></td>
                <td width="31"><p align="center">1</p></td>
              </tr>
              <tr>
                <td width="43"><p align="center">B</p></td>
                <td width="38"><p align="center">3</p></td>
                <td width="44"><p align="center">3</p></td>
                <td width="44"><p align="center">3</p></td>
                <td width="45"><p align="center">3</p></td>
                <td width="36"><p align="center">3</p></td>
                <td width="43"><p align="center">3</p></td>
                <td width="38"><p align="center">3</p></td>
                <td width="42"><p align="center">3</p></td>
                <td width="36"><p align="center">3</p></td>
                <td width="45"><p align="center">3</p></td>
                <td width="42"><p align="center">3</p></td>
                <td width="36"><p align="center">3</p></td>
                <td width="31"><p align="center">3</p></td>
                <td width="31"><p align="center">3</p></td>
              </tr>
              <tr>
                <td width="43"><p align="center">C</p></td>
                <td width="38"><p align="center">5</p></td>
                <td width="44"><p align="center">5</p></td>
                <td width="44"><p align="center">5</p></td>
                <td width="45"><p align="center">5</p></td>
                <td width="36"><p align="center">5</p></td>
                <td width="43"><p align="center">5</p></td>
                <td width="38"><p align="center">5</p></td>
                <td width="42"><p align="center">5</p></td>
                <td width="36"><p align="center">5</p></td>
                <td width="45"><p align="center">5</p></td>
                <td width="42"><p align="center">5</p></td>
                <td width="36"><p align="center">5</p></td>
                <td width="31"><p align="center">5</p></td>
                <td width="31"><p align="center">5</p></td>
              </tr>
              <tr>
                <td width="43"><p align="center">D</p></td>
                <td width="38"><p align="center">7</p></td>
                <td width="44"><p align="center">7</p></td>
                <td width="44"><p align="center">7</p></td>
                <td width="45"><p align="center">7</p></td>
                <td width="36"><p align="center">7</p></td>
                <td width="43"><p align="center">7</p></td>
                <td width="38"><p align="center">7</p></td>
                <td width="42"><p align="center">7</p></td>
                <td width="36"><p align="center">7</p></td>
                <td width="45"><p align="center">7</p></td>
                <td width="42"><p align="center">7</p></td>
                <td width="36"><p align="center">7</p></td>
                <td width="31"><p align="center">7</p></td>
                <td width="31"><p align="center">7</p></td>
              </tr>
              <tr>
                <td width="43"><p align="center">E</p></td>
                <td width="38"><p align="center">&nbsp;</p></td>
                <td width="44"><p align="center">&nbsp;</p></td>
                <td width="44"><p align="center">&nbsp;</p></td>
                <td width="45"><p align="center">8</p></td>
                <td width="36"><p align="center">&nbsp;</p></td>
                <td width="43"><p align="center">&nbsp;</p></td>
                <td width="38"><p align="center">&nbsp;</p></td>
                <td width="42"><p align="center">8</p></td>
                <td width="36"><p align="center">&nbsp;</p></td>
                <td width="45"><p align="center">&nbsp;</p></td>
                <td width="42"><p align="center">&nbsp;</p></td>
                <td width="36"><p align="center">&nbsp;</p></td>
                <td width="31"><p align="center">&nbsp;</p></td>
                <td width="31"><p align="center">&nbsp;</p></td>
              </tr>
            </tbody>
          </table>
          <div class="line" style="padding: 10px 0">
            2、投资者风险承受能力类型及适配产品
          </div>
          <table border="1" cellspacing="0" cellpadding="0" width="100%">
            <tbody>
              <tr>
                <td width="141" valign="top">
                  <p align="center"><strong>承受能力类型 </strong></p>
                </td>
                <td width="141" valign="top">
                  <p align="center"><strong>保守型 </strong></p>
                </td>
                <td width="141" valign="top">
                  <p align="center"><strong>稳健型 </strong></p>
                </td>
                <td width="141" valign="top">
                  <p align="center"><strong>积极型 </strong></p>
                </td>
              </tr>
              <tr>
                <td width="141" valign="top">
                  <p align="center"><strong>得分 </strong></p>
                </td>
                <td width="141" valign="top">
                  <p align="center"><strong>0-33</strong></p>
                </td>
                <td width="141" valign="top">
                  <p align="center"><strong>34-67</strong></p>
                </td>
                <td width="141" valign="top">
                  <p align="center"><strong>68-100</strong></p>
                </td>
              </tr>
              <tr>
                <td width="141" valign="top">
                  <p align="center"><strong>适配产品 </strong></p>
                </td>
                <td width="141" valign="top">
                  <p align="center"><strong>低风险产品 </strong></p>
                </td>
                <td width="141" valign="top">
                  <p align="center"><strong>低/中风险产品 </strong></p>
                </td>
                <td width="141" valign="top">
                  <p align="center"><strong>低/中/高风险产品 </strong></p>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="huiyuan_pinggu1">
            答题分数：<b>{{ dati.df }}分</b>
          </div>
          <div class="huiyuan_pinggu1">
            答题次数：<b>{{ dati.dc }}次</b>
          </div>
          <div class="huiyuan_pinggu1">
            答题时间：<b>{{ dati.ddata }}</b>
          </div>
          <div style="clear: both"></div>
          <div class="huiyuan_pinggu2">*评测结果有效期为3年。</div>
        </div>
        <div>
          <a class="btn1" href="'toprint'">打印</a>
          <a class="btn1" :href="'/question'">重新答题</a>
        </div>
      </div>

      <!-- 密码修改 -->
      <div class="right" v-if="menu == 3">
        <h3 v-if="$width >= 767">密码修改</h3>
        <h3 v-if="$width < 767" style="padding: 15px 0; margin-bottom: 10px">
          密码修改
        </h3>
        <el-row class="formrow">
          <el-col
            :span="$width >= 767 ? 4 : 6"
            :offset="$width >= 767 ? 2 : 1"
            class="formname"
            >旧密码</el-col
          >
          <el-col :span="14">
            <el-input
              type="password"
              v-model="resetPwd.oldPassword"
              placeholder="请输入旧密码"
            ></el-input>
          </el-col>
        </el-row>
        <el-row class="formrow">
          <el-col
            :span="$width >= 767 ? 4 : 6"
            :offset="$width >= 767 ? 2 : 1"
            class="formname"
            >新密码</el-col
          >
          <el-col :span="14">
            <el-input
              type="password"
              v-model="resetPwd.newPassword"
              placeholder="请输入新密码"
            ></el-input>
          </el-col>
        </el-row>
        <el-row class="formrow">
          <el-col
            :span="$width >= 767 ? 4 : 6"
            :offset="$width >= 767 ? 2 : 1"
            class="formname"
            >确定密码</el-col
          >
          <el-col :span="14">
            <el-input
              type="password"
              v-model="resetPwd.newPassword1"
              placeholder="请再次输入新密码"
            ></el-input>
          </el-col>
        </el-row>
        <el-row class="formrow">
          <el-col :span="14" :offset="5" class="btn" @click.native="updatePwd">
            确定修改
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "user",
  data() {
    return {
      menu: 0,
      itsActive: 0,
      user: {},
      resetPwd: { oldPassword: null, newPassword: null, newPassword1: null },
      gmlist: [],
      dati: { df: 0, dc: 0, ddata: 0 },
    };
  },
  methods: {
    tomenu(menu, itsactive) {
      this.menu = menu;
      if (itsactive !== undefined) {
        this.itsActive = itsactive;
      }
      if (itsactive == 0) {
        this.myGmlist();
      }
      if (this.menu == 2) {
        this.getResultScore();
      }
    },
    getUserinfo() {
      api.getInfo().then((res) => {
        if (res.code == 200) {
          this.$set(this, "user", res.user);
        }
      });
    },
    updateUserinfo() {
      api.edit(this.user).then((res) => {
        if (res.code == 200) {
          this.$set(this, "user", res.user);
          this.$message.success("个人信息修改成功");
        }
      });
    },
    myGmlist() {
      api.myGmlist().then((res) => {
        if (res.code == 200) {
          this.$set(this, "user", res.user);
        }
      });
    },
    updatePwd() {
      if (this.resetPwd.oldPassword == null) {
        this.$message.error("旧密码不能为空");
        return;
      }
      if (this.resetPwd.newPassword == null) {
        this.$message.error("新密码不能为空");
        return;
      }
      if (this.resetPwd.newPassword != this.resetPwd.newPassword1) {
        this.$message.error("两次新密码不一致");
        return;
      }
      api.updatePwd(this.resetPwd).then((res) => {
        if (res.code == 200) {
          this.$set(this, "user", res.user);
          this.$message.success("密码修改成功");
        }
      });
    },
    getResultScore() {
      api.getResultScore().then((res) => {
        if (res.code == 200 && res.msg == "操作成功") {
          this.dati.df = res.data.df;
          this.dati.dc = res.data.dc;
          this.dati.ddata = res.data.ddata;
        }
      });
    },
  },
  mounted() {
    this.getUserinfo();
  },
};
</script>
<style scoped>
.user {
  background: #f4f4f4;
  display: flex;
  width: 96%;
  padding: 25px 2%;
}
.user .left {
  background: #fff;
  padding: 0 30px 40px;
  width: 20%;
  margin-right: 2%;
  border-radius: 4px;
}
.user .right {
  width: 78%;
  padding: 0 30px 40px;
  background: #fff;
  border-radius: 4px;
}
h3 {
  font-size: 24px;
  border-bottom: 1px solid #9b9b9b;
  line-height: 45px;
}
.left .name {
  padding: 20px 0 10px;
  font-size: 20px;
  color: #333333;
  display: flex;
}
.name img {
  margin-right: 10px;
  width: 28px;
}
.left .name1 {
  padding-left: 35px;
  width: 100%;
  line-height: 30px;
  color: #666;
  cursor: pointer;
  transition: 500ms;
}
.left .name1:hover {
  color: #ec6526;
}
.huiyuan_box_r_home_name {
  font-size: 24px;
  padding: 30px 0 40px;
}
.huiyuan_box_r_home_ziliao {
  display: flex;
  flex-direction: row;
  align-content: center;
  line-height: 32px;
  height: 32px;
  color: #333;
  padding-bottom: 25px;
  border-bottom: 5px solid #cccccc;
}
.nav {
  height: 50px;
  line-height: 50px;
  padding: 20px 0px 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.nav span {
  /* background: #ec6526; */
  color: #333;
  padding: 0px 30px;
  display: inline-block;
  font-size: 18px;
  transition: 300ms;
  cursor: pointer;
}
.nav .active {
  background: #ec6526;
  color: white;
}

.th {
  height: 60px;
  line-height: 60px;
  background: #f4f4f4;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.tr {
  height: 50px;
  line-height: 50px;
  background: #fff;
  text-align: center;
  font-size: 14px;
  border-bottom: 1px solid #f4f4f4;
  color: #666666;
}
.tr2 {
  background: #fafafa;
}
.tr:hover {
  background: #ec6526;
  color: white;
}
.tr .btn {
  background: #ec6526;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
}
.tr:hover .btn {
  background: #fff;
  color: #ec6526;
  padding: 5px 10px;
}
.page {
  margin: 20px auto;
}
.formrow {
  margin-bottom: 25px;
}
.formrow .btn {
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #ec6526;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}
.formname {
  height: 40px;
  line-height: 40px;
  text-align: right;
  padding-right: 20px;
}
.el-input__inner {
  background: #f4f4f4;
}
.pinggu {
  color: #333;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px dotted #838383;
}
.pinggu .line {
  line-height: 22px;
  font-size: 14px;
}
.pinggu .underline {
  text-decoration: underline;
  padding: 0 5px;
}
.huiyuan_pinggu1 {
  line-height: 35px;
  height: 35px;
  float: left;
  padding-right: 10%;
}
.huiyuan_pinggu2 {
  line-height: 35px;
  height: 35px;
  color: #ff7c25;
  clear: both;
}
.btn1 {
  height: 30px;
  line-height: 30px;
  padding: 10px 30px;
  background: #eeeeee;
  margin-right: 20px;
}
.nocontent {
  text-align: center;
  margin: 30px auto 40px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .user {
    display: inline-block;
    padding: 10px 2% 25px;
  }
  .user .left {
    width: 92%;
    padding: 10px 4% 10px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  .user .right {
    width: 92%;
    padding: 0 4% 10px;
    border-radius: 4px;
  }
  h3 {
    line-height: 30px;
    padding: 0;
    margin: 0;
  }
}
</style>
